import React from 'react'
import { SbBlokData, StoryblokComponent } from '@storyblok/react'

type Props = {
  blok: SbBlokData & ISbSectionReferenceV2
}

const SectionReferenceV2 = ({ blok }: Props) => {
  return blok.body?.content?.body ? (
    blok.body.content.body.map((blok: ISbSectionV2, index: number) => <StoryblokComponent key={index} blok={blok} />)
  ) : (
    <div></div>
  )
}

export default SectionReferenceV2
